import { theme } from '@damen/ui'
import styled from 'styled-components'

// Made position absolute because component library changes could not be made (close proximity to go live)
// Fix is regarding bug 111850 https://dev.azure.com/DamenProjectHub/DXP/_workitems/edit/111950
export const TableIconWrapper = styled.span`
	margin-left: 8px;
	display: inline-block;
	position: absolute;
	right: 0;
	z-index: 1;
	cursor: pointer;
`

// p tag styling needed for AssetComponentsOverviewTable
export const TableItemTitle = styled.span`
	color: ${theme.colors.blue};
	text-decoration: underline;

	p {
		color: ${theme.colors.blue};
	}

	@media ${theme.breakpoints.md} {
		color: inherit;
		text-decoration: none;

		p {
			color: inherit;
		}
	}
`
