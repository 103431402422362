import { NextRouter } from 'next/router'

export const removeQueryStrings = (router: NextRouter) => {
	const browserUrl = router.asPath.split('?')

	// lenght is greater than 1 if there are query strings
	if (browserUrl.length > 1) {
		router.push(
			{
				pathname: browserUrl[0],
				query: {}
			},
			undefined,
			{ shallow: true }
		)
	}
}
